import React, { useEffect } from 'react';
import { useAuthContext } from 'src/components/AuthProvider';

import { Translate } from 'src/components/Translate';
import { LoadingThrobber } from 'src/components/atom/icons';

const i18n = {
  message: 'api.errorLink.redirect.sessionExpiredLogin',
};
const TIMESTAMP_LAST_LOGIN_REDIRECT = `TIMESTAMP_LAST_LOGIN_REDIRECT`;
const LAST_REDIRECT_GAP_TIME = 60 * 1000; // 1min

function clearActionTime() {
  sessionStorage.removeItem(TIMESTAMP_LAST_LOGIN_REDIRECT);
}

function logActionTime() {
  const now = new Date().getTime();
  sessionStorage.setItem(TIMESTAMP_LAST_LOGIN_REDIRECT, now.toString());
}

function getActionTimeDiff() {
  const now = new Date().getTime();
  const last: number = parseFloat(sessionStorage.getItem(TIMESTAMP_LAST_LOGIN_REDIRECT)) || 0;
  return now - last;
}

function LoginRedirect() {
  const { loading, login, logout } = useAuthContext();
  useEffect(() => {
    if (loading) return;

    const t = getActionTimeDiff();
    if (t > LAST_REDIRECT_GAP_TIME) {
      // If there was no redirect to login just now
      login();
      logActionTime();
    } else {
      // If last redirect to login was very recent
      // Purpose: is to prevent dead loop, in case of API failure (constantly returns 401), dead loop might happen
      logout();
      clearActionTime();
    }
  }, [loading]);

  return (
    <div style={{ textAlign: 'center' }}>
      <LoadingThrobber width={40} height={40} />
      <p className="text_3">
        <Translate resourceKey={i18n.message} />
      </p>
    </div>
  );
}

export default LoginRedirect;
